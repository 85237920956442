import { requests } from "@/utils/requests";

export const upload = async (formData) => {
  const data = await requests({
    url: `/api/service/files/upload`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const uploadInCase = async (formData, case_uuid) => {
  const data = await requests({
    url: `/api/service/files/upload/case/${case_uuid}`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const uploadInPost = async (formData, post_id) => {
  const data = await requests({
    url: `/api/service/files/upload/post/${post_id}`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const download = async (file_uuid) => {
  const data = await requests({
    url: `/api/service/files/download/${file_uuid}`,
    method: "GET",
    options: {
      responseType: "blob",
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const remove = async (file_uuid) => {
  const data = await requests({
    url: `/api/service/files/delete/${file_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  upload,
  download,
  uploadInCase,
  remove,
};
